import { CombData, playerCount } from "./CombData";

export interface rateCount {
    player_i: number,
    count1: number,
    count2: number,
    count3: number,
    count4: number,
    count5: number
}
export class Combination {
    combData: CombData;
    disablePlayers: number[]; // 除外する人のindexリスト

    constructor(combData: CombData) {
        this.combData = combData;
        this.disablePlayers = [];
    }

    // ペアキーの作成関数
    getNum = (num: number) => ('000' + num).slice(-3);
    getPairKey = (a: number, b: number) => `${this.getNum(Math.min(a, b))}-${this.getNum(Math.max(a, b))}`;
    deepCopy = (array: number[][]) => array.map(innerArray => [...innerArray]);
    deepCopyPlayerCount = (array: playerCount[]) => JSON.parse(JSON.stringify(array));
    // generateArray = (num: number): number[] => Array.from({ length: num }, (_, i) => i);
    getSliceArray = (array: any[], endIndex: number) => array.slice(0, endIndex);

    // 除外する人を抜いた配列を取得する
    getPlayers = (playerCounts: playerCount[], disablePlayers: number[]): playerCount[] => {
        var newList = [];

        for (const playerCount of playerCounts) {
            if (disablePlayers.includes(playerCount.player_i)) { continue; }
            newList.push(playerCount);
        }
        return newList;
    };

    // 数字の配列から差を出す
    getNumbersDiff = (array: number[]): number => {
        if (array.length === 0) {
            console.log('配列が空で0を返す');
            return 0;
        }

        if (array.length === 1) {
            return array[0];
        }

        var sort = array.sort((a, b) => {
            return a - b;
        });
        const min: number = sort[0];
        const max: number = sort[sort.length - 1];
        return max - min;
    }

    // 最少と最大を出す
    getPairMinMax = (rateCounts: rateCount[], key: string): { min: number, max: number } => {
        if (!(key in rateCounts[0])) {
            console.log("指定されたキーが存在しません");
            return { min: 0, max: 0 };
        }

        const _key = key as keyof rateCount;
        var sort = rateCounts.sort((a, b) => {
            return a[_key] - b[_key];
        });
        const min: number = sort[0][_key];
        const max: number = sort[sort.length - 1][_key];
        return {
            min: min,
            max: max
        };
    }

    sortPlayerCounts = (playCounts: playerCount[]) => {
        return playCounts.sort((a, b) => {
            if (a.count !== b.count) { // 回数が違う場合
                return a.count - b.count; // 回数で昇順ソート
            } else {
                return a.player_i - b.player_i; // 回数が同じ場合はplayer_iで昇順ソート
            }
        });
    }

    sortRateCounts = (rateCounts: rateCount[]) => {
        return rateCounts.sort((a, b) => {
            const countsA = [a.count1, a.count2, a.count3, a.count4]; // countを配列でまとめる
            const countsB = [b.count1, b.count2, b.count3, b.count4];

            for (let i = 0; i < countsA.length; i++) {
                if (countsA[i] !== countsB[i]) {
                    return countsA[i] - countsB[i]; // 各カウントを比較
                }
            }

            // 全てのカウントが同じ場合、player_iでソート
            return a.player_i - b.player_i;
        });
    }


    /**
     * 計算を開始するメソッド
     */
    calMatchese(): CombData {
        for (let i = 0; i < this.combData.battleCnt * 4; i++) {
            this.step();
        }
        if (this.combData.pairing.length > 0) { // 調査用
            this.combData.pairings.add([...this.combData.pairing]); // 対戦リストに追加する
        }

        return this.combData;
    }

    /**
     * 途中から計算を開始するメソッド
     */
    reCalMatches(nowBattleCnt: number, addPlayerCnt: number, disablePlayers: number[]): CombData {
        var initPlayerCnt = this.combData.playerCnt;

        this.disablePlayers = disablePlayers; // 除外する人定義 

        // 人数を追加する
        this.combData.playerCnt = this.combData.playerCnt + addPlayerCnt;
        // 一旦,試合回数は同じとする
        this.combData.pairings = new Set(this.getSliceArray([...Array.from(this.combData.pairings)], nowBattleCnt));
        this.combData.playCounts = this.getSliceArray([...this.combData.playCounts], nowBattleCnt);

        this.combData.resultMatches = this.getSliceArray([...this.combData.resultMatches], nowBattleCnt);
        this.combData.matchCounts = [...this.combData.resultMatches[nowBattleCnt - 1]];
        // 新規の人で組まないように最大の値を入れている
        var match_0_index: number[] = [...this.combData.matchCounts[0]];
        match_0_index = match_0_index.slice(1, match_0_index.length - 1)
        var match_max: number = Math.max(...match_0_index);
        var initMatch = Array.from({ length: this.combData.playerCnt }, (): number[] => Array(this.combData.playerCnt).fill(0));

        //一番最後の試合のデータを代入する
        this.combData.resultPlayCounts = this.getSliceArray([...this.combData.resultPlayCounts], nowBattleCnt);
        this.combData.playCounts = this.deepCopyPlayerCount([...this.combData.resultPlayCounts[nowBattleCnt - 1]]);

        //　最少の値のカウントを追加する
        var _joinPlayerCounts = this.getPlayers(this.combData.playCounts, this.disablePlayers);// 最少の試合回数を取得
        _joinPlayerCounts = this.sortPlayerCounts(_joinPlayerCounts);
        var max = _joinPlayerCounts[_joinPlayerCounts.length - 1].count;

        for (let i = 0; i < addPlayerCnt; i++) {
            const playerCount: playerCount = { player_i: this.combData.playerCnt + i - addPlayerCnt, count: max - 1 }
            this.combData.playCounts.push(playerCount);
        }

        for (let i = 0; i < this.combData.playerCnt; i++) {
            for (let j = 0; j < this.combData.playerCnt; j++) {
                if (i >= initPlayerCnt && j >= initPlayerCnt) {
                    initMatch[i][j] = match_max; // 追加の人同士はなるべく組まないように
                } else {
                    initMatch[i][j] = this.combData.matchCounts?.[i]?.[j] ?? match_max - 1; // 範囲外は0
                }
            }
        }
        this.combData.matchCounts = initMatch;
        for (let i = 0; i < (this.combData.battleCnt - nowBattleCnt) * 4; i++) {
            this.step();
        }

        this.disablePlayers = [];// 終わった後は、初期化しておく
        return this.combData;
    }

    // 1人ずつ対戦相手を決める処理
    step(): void {
        // 現時点の適した人を追加する
        const matchPlayer_i = this.matchPair();
        this.combData.pairing.push(matchPlayer_i);

        // 【②】対戦結果カウントを増やす
        for (var i = 0; i < this.combData.pairing.length - 1; i++) {
            this.combData.matchCounts[this.combData.pairing[i]][matchPlayer_i] += 1;
            this.combData.matchCounts[matchPlayer_i][this.combData.pairing[i]] += 1;
        }

        if (this.combData.pairing.length >= 4) {
            this.combData.resultMatches.push(this.deepCopy(this.combData.matchCounts));
            this.combData.resultPlayCounts.push(this.deepCopyPlayerCount(this.combData.playCounts));

            // 入れ替えた結果数値が低くなる場合は入れ替えを行う
            // if (this.isSamePair(this.combData.pairing[2], this.combData.pairing[3])) {
            //     if (!this.isSamePair(this.combData.pairing[0], this.combData.pairing[2]) && !this.isSamePair(this.combData.pairing[1], this.combData.pairing[3])) {// [1,3,2,4]
            //         this.combData.pairing = [this.combData.pairing[0], this.combData.pairing[2], this.combData.pairing[1], this.combData.pairing[3]];
            //     }
            //     else if (!this.isSamePair(this.combData.pairing[0], this.combData.pairing[3]) && !this.isSamePair(this.combData.pairing[1], this.combData.pairing[2])) {// [1,4,2,3]
            //         this.combData.pairing = [this.combData.pairing[0], this.combData.pairing[3], this.combData.pairing[1], this.combData.pairing[2]];
            //     }
            // }

            // ペアを格納する
            this.addPair(this.getPairKey(this.combData.pairing[0], this.combData.pairing[1]));
            this.addPair(this.getPairKey(this.combData.pairing[2], this.combData.pairing[3]));

            this.combData.pairings.add([this.combData.pairing[0] + 0, this.combData.pairing[1] + 0, this.combData.pairing[2] + 0, this.combData.pairing[3] + 0]); // 対戦リストに追加する
            this.combData.pairing = []; // 空にする
        }
    }

    // 最少の数を元に対戦相手を決める(再起処理)
    matchPair(): number {
        // 不参加の人は除外
        var _joinPlayerCounts = this.getPlayers(this.combData.playCounts, this.disablePlayers);
        // 既に選択中の人は除外
        _joinPlayerCounts = this.getPlayers(_joinPlayerCounts, this.combData.pairing);
        // 前回入った人は除外（10人目から）
        var _pairings = [...Array.from(this.combData.pairings)];
        var _playerCnt = this.combData.playerCnt - this.disablePlayers.length;
        if (_pairings.length > 0 && _playerCnt > 9) {
            _joinPlayerCounts = this.getPlayers(_joinPlayerCounts, _pairings[_pairings.length - 1]);
        }

        const _rateCounts = this.sortRatePlayer(_joinPlayerCounts);

        var newList: rateCount[] = this.filterRateCounts(_rateCounts);

        if (_rateCounts.length > 0) { // 不参加、前回対戦、既に選択中以外で残り０人
            var selectPlayer_i = 0;
            if (newList.length > 0) { //　ペア、試合回数の差が最小から2を超える場合
                selectPlayer_i = newList[0].player_i;
            } else {
                selectPlayer_i = _rateCounts[0].player_i;
            }
            this.combData.playCounts[selectPlayer_i].count += 1; //対戦回数を増やす
            return selectPlayer_i; // 対戦者が１人確定する
        } else {
            throw new Error('除外後に0人');
        }
    }

    /**
     * 参加者の順番を評価値順に並び替える
     */
    sortRatePlayer(joinPlayerCounts: playerCount[]): rateCount[] {
        var rateList: rateCount[] = []; // 初期値として対戦回数を入れる

        // 既に決まっている対戦相手から評価値を計算する。
        for (const playerCounts of joinPlayerCounts) {
            var pairCnt = 0;
            var ratingValues = []; // 差を出すために配列に入れてから後で計算
            // 2人目は以降は対戦する人のバランスも評価に含める
            for (const player_i of this.combData.pairing) {
                const matchCount = this.combData.matchCounts[player_i][playerCounts.player_i];
                ratingValues.push(matchCount === 0 ? -5 : matchCount); // 0回目のペアの評価値を高めに
            }

            // ③ペアチェック　※2、4人目の場合
            if (this.combData.pairing.length % 2 === 1) {
                // 最終的にはペアになった回数×50のように決める
                pairCnt = this.getPairCount(this.combData.pairing[this.combData.pairing.length - 1], playerCounts.player_i);
            }
            const ratingSum = ratingValues.reduce((a, b) => a + b, 0);

            rateList.push({
                player_i: playerCounts.player_i,
                count1: playerCounts.count, // 試合回数
                count2: pairCnt, // ペアの回数（最大値の場合99にする）
                count3: playerCounts.count + ratingSum, // 試合回数 + 評価値
                count4: this.getNumbersDiff(ratingValues), // 各評価値の差
                count5: ratingSum, // 評価値 必要ないがスキップする用
            });
        }

        //　評価が高い順(数値が低い)でソートして返却
        return this.sortRateCounts(rateList);
    }

    // ペアを追加する
    addPair(pairKey: string) {
        if (pairKey in this.combData.playedPairs) {
            this.combData.playedPairs[pairKey] += 1;
        } else {
            this.combData.playedPairs[pairKey] = 1;
        }
    }

    // ペアの数を返却する
    getPairCount(player1: number, player2: number): number {
        const pairKey = this.getPairKey(player1, player2);
        if (pairKey in this.combData.playedPairs) {
            return this.combData.playedPairs[pairKey];
        } else {
            return 0; // ない場合は0
        }
    }

    // ペア数と試合回数が2以上ある場合場外
    filterRateCounts(_rateCounts: rateCount[]): rateCount[] {
        var result = this.filterByCountDiff(_rateCounts, 'count1', 2);
        result = this.filterByCountDiff(result, 'count2', 2);
        result = this.filterByCountDiff(result, 'count5', 2);
        return result;
    }

    filterByCountDiff(rateCounts: rateCount[], countKey: keyof rateCount, diffNum: number): rateCount[] {
        const newList: rateCount[] = [];
        const minMax = this.getPairMinMax(rateCounts, countKey);

        for (const rateCount of rateCounts) {
            if (minMax.max - minMax.min >= diffNum && minMax.max === rateCount[countKey]) {
                continue; // 最大-最小が2以上の場合の最大値は除外(2-0の場合は2が除外)
            }
            newList.push(rateCount);
        }

        return newList;
    }


    // ③同じペアが発生する場合はtrueを返す　※ペアの配列は昇順にしてから格納しておく
    // isSamePair(player1: number, player2: number): boolean {
    //     const player1Key = this.getNum(player1);
    //     const player2Key = this.getNum(player2);
    //     // もしプレイヤーの全ての組み合わせを行なっている場合は削除する
    //     let count1 = 0;
    //     let count2 = 0;
    //     this.combData.playedPairs.forEach((pair) => {
    //         if (pair.includes(player1Key)) count1++;
    //         if (pair.includes(player2Key)) count2++;
    //     });

    //     // 規定数以上の組み合わせがある場合は削除
    //     const isDaletePlayer1 = count1 === this.combData.playerCnt - 1;
    //     const isDaletePlayer2 = count2 === this.combData.playerCnt - 1;

    //     if (isDaletePlayer1 || isDaletePlayer2) {
    //         this.combData.playedPairs.forEach((pair) => {
    //             if (
    //                 (isDaletePlayer1 && pair.includes(player1Key)) ||
    //                 (isDaletePlayer2 && pair.includes(player2Key))
    //             ) {
    //                 this.combData.playedPairs.delete(pair);
    //             }
    //         });
    //     }

    //     return this.combData.playedPairs.has(this.getPairKey(player1, player2)); //　同じペアが発生する場合はtrueを返す
    // }

    // 試合の参加者を入れ替えてペアが被らなければその順番にする
    // pairingSwap(pairing: number[], player_i: number): boolean { // 例)[1,2,3,4]の場合
    //     if (!this.isSamePair(pairing[0], pairing[2]) && !this.isSamePair(pairing[1], player_i)) {// [1,3,2,4]
    //         return true;
    //     }

    //     if (!this.isSamePair(pairing[0], player_i) && !this.isSamePair(pairing[1], pairing[2])) {// [1,4,2,3]
    //         return true;
    //     }
    //     return false;
    // }
}
