import { useEffect, useState } from 'react';
import '../css/App.css';
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { CombData } from "./CombData";
import { Combination } from "./Combination";
import Button from './component/Button';
import Card from './component/Card';

// import { AdsInFeed } from './component/AdsInFeed';

function Home() {
	const navigate = useNavigate();
	const location = useLocation();
	const locationState = location.state;
	const nowBattleCnt: number | null = locationState?.nowBattleCnt ?? null;
	const resultData: CombData | null = locationState?.combData ?? null;

	const [playerCnt, setPlayerCnt] = useState<number>(() => {
		return parseInt(localStorage.getItem('playerCnt') || '10');
	}); // 参加人数
	const [battleCnt, setBattleCnt] = useState<number>(() => {
		return parseInt(localStorage.getItem('battleCnt') || '50');
	}); // 対戦回数
	const generateArray = (num: number): number[] => Array.from({ length: num }, (_, i) => i);
	const [displayPlayers, setDisplayPlayers] = useState<number[]>([]); // 除外する番号

	const players = generateArray(resultData?.playerCnt ?? 0); // 戻ってきた時の人数
	// 画面に戻ってきた時の処理
	useEffect(() => {
		localStorage.setItem('playerCnt', playerCnt.toString());
		localStorage.setItem('battleCnt', battleCnt.toString());

	}, [playerCnt, battleCnt]);

	// リセットボタンが押された時
	const reloadClick = () => {
		var root = document.getElementById('root');
		if (root?.dataset.version !== '1.0.2') {
			window.location.href = window.location.href + ''; // エラー回避の為（同じURLを代入できないため） 
		}

		setPlayerCnt(10);
		setBattleCnt(50);
		localStorage.clear();
		navigate(window.location.pathname, { replace: true, state: null });
	}

	// 「開始」ボタンをクリック
	const handleClick = () => {
		const combData = new CombData(playerCnt, battleCnt);
		const comb = new Combination(combData);
		comb.calMatchese(); // 対戦相手を計算する
		navigate("/list", {
			state: {
				combData: combData,
			}
		});
	};

	// 「開始」ボタンをクリック
	const handleClick2 = () => {
		if (resultData) {
			const comb = new Combination(resultData);
			const ccc = comb.reCalMatches(nowBattleCnt ?? 0, playerCnt - (resultData?.playerCnt ?? 0), displayPlayers);

			navigate("/list", {
				state: {
					combData: ccc,
				}
			});
		}
	};

	return (
		<>
			<header className="App-header">
				<h1 className="flex justify-between items-center">
					{/* <svg className="h-6 w-8" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <circle cx="12" cy="12" r="9" />    <path d="M6 5.3a9 9 0 0 1 0 13.4" />    <path d="M6 5.3a9 9 0 0 1 0 13.4" transform="rotate(180 12 12)" />  </svg> */}
					<span className='font-bold'>ダブルス組み合わせ表の作成ツール</span>
					{/* <svg className="h-6 w-8" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <circle cx="12" cy="12" r="9" />    <path d="M6 5.3a9 9 0 0 1 0 13.4" />    <path d="M6 5.3a9 9 0 0 1 0 13.4" transform="rotate(180 12 12)" />  </svg> */}
				</h1>
			</header >

			<div className="App relative mx-auto p-2">
				<div className='w-full flex justify-between'>
					<Button onClick={reloadClick}> リセット</Button>

					<div className='justify-end'>
						<div className={`text-end text-sm  ${nowBattleCnt ? '' : 'invisible'}`}>
							現在:<span className='text-lg mx-1'>{nowBattleCnt}</span>試合
							<span className='text-lg mx-1'>{resultData?.playerCnt ?? 0}</span>人

						</div>
						<div className="text-gray-500 text-sm text-end">
							<span>試合回数</span>
							<input
								className='mx-1 w-10 bg-green-50 opacity-70 text-center text-base text-black border border-gray-400 rounded-md font-semibold'
								type="number"
								value={battleCnt}
								onChange={(e) => {
									const target_num: number = parseInt(e.target.value === '' ? '0' : e.target.value);
									setBattleCnt(target_num);
								}}
							/>
							<span>回</span>
						</div>

						<div className="mt-0.5 text-gray-500 text-sm text-end">
							<span>参加人数</span>
							<input
								className='mx-1 w-10 bg-green-50 opacity-70 text-center text-base text-black border border-gray-400 rounded-md font-semibold'
								type="number"
								minLength={0}
								maxLength={50}
								value={playerCnt}
								onChange={(e) => {
									const target_num: number = parseInt(e.target.value === '' ? '0' : e.target.value);
									setPlayerCnt(target_num);
								}}
							/>
							<span>人</span>
						</div>
					</div>
				</div>

				<h3 className='font-bold text-start'>人数選択</h3>
				<Card className='m-2'>
					<div className='grid grid-cols-7'>
						{generateArray(28).map((date, index) => { // 一旦、31人までボタン表示 
							return (
								<button key={index}
									className={`${index + 4 < (resultData?.playerCnt ?? 0) ? 'text-gray-300' : 'border rounded-md'} ${index + 4 === playerCnt ? 'bg-green-700 text-white border-black font-bold' : 'text-gray-500 border-gray-400'} m-1 w-11 `}
									onClick={index + 4 < (resultData?.playerCnt ?? 0) ? () => { } : () => {
										setPlayerCnt(index + 4);
									}}
								>{index + 4}<span className="text-xs">人</span></button>
							)
						})}
					</div>
				</Card>

				{resultData !== null &&
					(<>
						<h3 className='font-bold text-start'>不参加の番号</h3>
						<Card className='mt-2'>

							<div className='mx-auto w-64 justify-items-center grid grid-cols-5 gap-0'>
								{players.map((date, index) => {
									var isUse: Boolean = !displayPlayers.includes(index);
									return (
										<button
											key={index}
											className={`${isUse ? 'bg-green-700 text-white' : 'text-gray-400'} m-1 w-10  border border-gray-400 rounded-md`}
											onClick={() => {
												// 既にある場合は削除しない場合は追加する。
												if (isUse) {
													setDisplayPlayers([...displayPlayers, index]);
												} else {
													setDisplayPlayers(displayPlayers.filter(num => num !== index));
												}
											}}
										>{index + 1}</button>
									)
								})}
							</div>
						</Card>
					</>)}

				<Button onClick={locationState !== null ? handleClick2 : handleClick} className='mt-2 w-20 bg-green-700'>{resultData !== null ? '再開' : '開始'}</Button>
			</div >
			<div className='mt-2 p-2'>
				<h3>使い方</h3>
				<div className='text-sm'>
					<div>『人数選択』の人数を選択して、『開始』ボタンをクリックしてください。</div>
					{resultData !== null &&
						(<div>
							不参加の人がいる場合は『不参加の番号』の数字を選択してください
						</div>)}
				</div>
			</div>
		</>
	);
}

export default Home;